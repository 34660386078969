.menuIconSelect:hover {
  background-color: #a693fa62;
  border-radius: 0.75vw;
}

.menuIconPersonContent {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-top: 10vh;
}

.menuIcon,
.menuIconSelect,
.menuIconExit,
.menuIconSelected,
.menuIconSelectedApresentacao,
.menuIconSelectApresentacao {
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Alinhar ícone e texto à esquerda */
  align-items: center; /* Alinhar verticalmente o ícone e o texto */
  height: 6vh;
  font-family: 'Public Sans', sans-serif;
  font-size: 0.75vw;
  font-weight: 600;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-radius 0.1s ease-in-out;
  border-radius: 1vw; /* Garantir que o border-radius sempre esteja aplicado */
}

.menuIconSelectedApresentacao {
  background-color: #DDD6FD;
  color: #595959;
  border-radius: 15px;
  width: 85%;
  text-align: left;
  justify-content: flex-start; /* Alinhar ícone e texto à esquerda */
  padding-left: 1vw;
}

.menuIconSelectApresentacao {
  color: #595959;
  width: 85%;
  text-align: left;
  justify-content: flex-start; /* Alinhar ícone e texto à esquerda */
  padding-left: 1vw;
}

.menuIconSelectApresentacao:hover {
  background-color: #a693fa62;
  color: #595959;
  border-radius: 0.75vw;
  width: 85%;
  text-align: left;
  justify-content: flex-start;
  padding-left: 1vw;
}

.menuIconSelected {
  background-color: #DDD6FD;
  color: #595959;
  border-radius: 0.75vw;
  width: 10vw;
  text-align: left;
  justify-content: flex-start; /* Alinhar ícone e texto à esquerda */
  padding-left: 1vw;
  height: 5vh;
}

.menuIcon,
.menuIconSelect,
.menuIconExit {
  color: #595959;
  width: 10vw;
  text-align: left;
  justify-content: flex-start; /* Alinhar ícone e texto à esquerda */
  padding-left: 1vw;
  height: 5vh;
}

.menuIcon,
.menuIconExit {
  height: 10vh;
}

.menuIconExit{
  cursor: pointer;
  width: 12.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #0D1A21;
}

.App {
  display: grid;
  grid-template-columns: 1fr;
  width: 12.5vw; /* Largura do menu expandido */
  height: 100%;
  background-color: white;
  position: fixed;
  z-index: 9999999;
  transition: width 0.1s ease-in-out; /* Animação de largura */
  border-right: 2px solid #f0f0f8;
}

/* Classe para o menu colapsado */
.collapsed {
  width: 60px; /* Define a largura do menu quando colapsado */
}

/* Centralizar ícones quando o menu está colapsado */
.collapsed .menuIconSelectedApresentacao, 
.collapsed .menuIconSelectApresentacao, 
.collapsed .menuIconSelected, 
.collapsed .menuIconSelect {
  justify-content: center; /* Centralizar os ícones no menu colapsado */
  padding-left: 0; /* Remover padding para centralização */
}

.menuIconSelect, 
.menuIconSelected, 
.menuIconSelectApresentacao, 
.menuIconSelectedApresentacao {
  display: flex;
  align-items: center; /* Garantir que o texto e o ícone estejam verticalmente alinhados */
  cursor: pointer;
  margin-bottom: 1.5vh; /* Espaçamento entre os itens */
  transition: margin-left 0.1s ease-in-out, opacity 0.1s ease-in-out; /* Transição combinada */
  height: 5vh;
}

.menuIconSelect div, 
.menuIconSelected div, 
.menuIconSelectApresentacao div, 
.menuIconSelectedApresentacao div {
  margin-left: 1vw;
  opacity: 1;
  transition: margin-left 0.1s ease-in-out, opacity 0.1s ease-in-out; /* Animação suave para o texto */
}

/* Esconde o texto quando o menu está colapsado */
.collapsed .menuIconSelect div, 
.collapsed .menuIconSelected div, 
.collapsed .menuIconSelectApresentacao div, 
.collapsed .menuIconSelectedApresentacao div, 
.collapsed .menuIconExit div {
  margin-left: 0;
  opacity: 0;
  pointer-events: none; /* Remover a interação com o texto oculto */
}

.menuIconPersonContent > div div,
.menuIconExit div {
  display: block;
}

.collapsed .menuIconPersonContent > div div,
.collapsed .menuIconExit div {
  opacity: 0; /* Esconder o texto suavemente */
  pointer-events: none; /* Remover a interação com o texto oculto */
}

.textBar {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
}

.menuText {
  font-family: 'Spinnaker', sans-serif; /* Aplicar a fonte Spinnaker */
  font-size: 1.5vw;
  font-weight: 500;
  color: #281773; /* Cor padrão */
  transition: color 0.1s ease-in-out, margin-left 0.1s ease-in-out; /* Transição para a cor e o espaçamento */
  margin-left: 10px; /* Espaçamento entre o ícone e o texto */

  width: 12.5vw;
  text-align: center;
}

.collapsed .menuText {
  color: #281773; /* Cor roxa quando colapsado */
  font-size: 18px; /* Ajustar o tamanho para a letra S */
  margin-left: 5px; /* Diminuir o espaçamento entre o ícone e o S */
}


.divisor{
  width: 10vw;
  height: 0.5vh;
  background-color: #EFF3F4;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.name-option{
  font-size: 0.7vw;
  width: 7vw;
}