.body {
  width: calc(100% - 12.5vw);
  height: calc(100% - 12vw);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5vw;
  padding-bottom: 1vw;
  background-color: #EFF3F4;
}

.body-Data {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;
  height: 10vh;
  background-color: white;
}

.BodyHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 12.5vw);
}

.BodyHeader-Data {
  width: calc(100% - 12.5vw);
  height: 100%;
  margin-left: 12.5vw;
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow-y: scroll;
}

.BodyHeader,
.menubar {
  width: 100%;
  height: 100%;
}

.BodyHeader{
  width: 87.5vw;
}

.menubar{
  z-index: 999;
}

.App {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 12.5vw 77.5vw;
  
  background-color: #EFF3F4;
}
