.App {
  width: 100%;
  min-height: 100vh;
  background-color: #eff3f4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-info {
  display: flex;
  width: 70vw;
  align-items: center;
  gap: 1.4vw;
  max-height: 150px;
  height: auto;
}

.number-users {
  width: 13vw;
  height: auto;
  background-color: #b8abf8;

  box-shadow: 4px 4px 15px 2px #00000005;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
}

.title {
  color: white;
  padding-left: 5%;
  padding-top: 5%;
  font-size: 0.7vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5vw;
}

.subtitle {
  font-size: 0.7vw;
  border-left: 0.25vw solid #fff;
  padding-left: 0.35vw;
  font-weight: 600;
}

.title-white {
  color: #9d9d9d;
  padding-left: 3.5%;
  padding-top: 2%;
  width: 100%;
  font-size: 0.7vw;
}

.title-card {
  color: #595959;
  padding-left: 3.5%;
  padding-top: 2%;
  width: 100%;
  font-size: 0.58vw;
  font-weight: 600;
  height: 55%;
  margin-top: 0.5vh;
}

.font-soft {
  font-size: 0.7vw;
  padding-left: 1vw;
  color: #bbbbbb;
}

.number {
  color: white;
  width: 100%;
  font-size: 3.5vw;
  text-align: end;
  padding-right: 1vw;
}

.grafic-users {
  width: 60vw;
  height: 20vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: row;
  background-color: white;
}

.icons {
  color: white;
}

.container-icon {
  justify-content: flex-end;
  width: 90%;
  display: flex;
  height: 5vh;
}

.column {
  width: 100%;
  padding-left: 2%;
  padding-top: 2%;
}

.column text {
  color: #9d9d9d;
}

.grafic-line,
.division-period,
.grafic-line-relation {
  display: flex;
  flex-direction: column;
  width: 25vw;
  background-color: white;
  border-radius: 1vw;
  box-shadow: 4px 4px 15px 2px #00000005;
}

.grafic-line,
.division-period {
  margin-top: 2.5vh;
}

.container-grafic-line,
.container-grafic {
  display: flex;
  flex-direction: column;
  width: 97.5%;
  height: 35vh;
  background-color: white;
  border-radius: 1vw;

  box-shadow: 4px 4px 15px 2px #00000005;
  margin-top: 2vw;
  align-items: end;
}

.container-grafic {
  align-items: center !important;
  height: auto !important;
}

.container-grafic-area {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  border-radius: 1vw;
  box-shadow: 4px 4px 15px 2px #00000005;
  justify-items: center;
  align-items: center;
  margin-top: 2vh;
  gap: 2vh;
  margin-bottom: 5vh;
}

.btn {
  outline: none;
  border: none;
  background-color: #b8abf8;

  color: white;
  font-size: 0.7vw;
  margin: 2%;
  border-radius: 0.4vw;
  transition: 0.3s ease-in-out;
  height: 4vh !important;
}

.btn:hover {
  box-shadow: 2px 2px 5px 1px #dbdbdb;
  transition: 0.3s ease-in-out;
  scale: 1.05;
  background-color: #ae9eff;
}

.title-module {
  margin-top: 1.5%;
  font-size: 1.5vw;
  color: #595959;
  font-weight: 500;
  width: 70vw;
  height: 5vh;
}

.label {
  font-weight: 600;
}

.division {
  display: grid;
  grid-template-columns: 57vw 25vw;
  padding: 0;
  align-items: start;
  margin-top: 2.5vh;
  margin-bottom: 10vh;
}

.division-period-start-end {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 3%;
}

.container-dates {
  width: 10vw;
  height: 10vh;
  box-shadow: 4px 4px 15px 2px #00000015;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  padding: 2%;
  margin-bottom: 1.5vh;
}

.containe-dates-text-icon {
  display: grid;
  grid-template-columns: 65% 35%;
  justify-content: center;
  align-items: center;
}

.container-dates-text {
  display: flex;
  flex-direction: column;
}

.cube-icon {
  width: 3vw;
  height: 6vh;
  background-color: #f2effe;
  border-radius: 0.75vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-icon {
  width: 2vw;
  height: 4vh;
  background-color: #b8abf8;
  border-radius: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.legend {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  margin-top: 5%;
  width: 100%;
  gap: 2vw;
}

.legend-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
}

.cube-legend-interno,
.cube-legend-externo {
  width: 2vh;
  height: 2vh;
  border-radius: 0.7vh;
}

.cube-legend-interno {
  background-color: #b8abf8;
}

.cube-legend-externo {
  background-color: #ddd6fd;
}

.font-soft-legend {
  font-size: 0.6vw;
  padding-left: 0.5vw;
  color: #bbbbbb;
}

.font-heavy-legend {
  font-size: 0.6vw;
  padding-left: 0.5vw;
  color: #838383;
}

.container-bar {
  width: 22.5vw;
  height: 7.5vh;
  background-color: #fff;
  margin-top: 1vh;
  margin-left: 1.25vw;
  border-radius: 0.5vw;
  box-shadow: 4px 4px 15px 2px #00000015;
  display: grid;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vh;
}

.bar-interno,
.bar-externo {
  width: 98%;
  height: 7.3vh;
}

.bar-interno {
  border-radius: 0.5vw 0 0 0.5vw;
  background-color: #b8abf8;
  margin-left: 1%;
}

.bar-externo {
  border-radius: 0 0.5vw 0.5vw 0;
  background-color: #ddd6fd;
  margin-right: 3%;
}

.container-information-area {
  width: 12vw;
  height: 11vh;
  border-radius: 0.75vw;
  background-color: white;
  box-shadow: 4px 4px 15px 2px #00000015;
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 2%;
}

.informations-area {
  display: flex;
  flex-direction: column;
}

.second-text{
  color: #BBBBBB;
  padding-left: 3.5%;
  padding-top: 2%;
  width: 100%;
  font-size: 0.5vw;
  font-weight: 700;
  height: 15%;
}

.green{
  width: 2.75vw;
  height: 5.5vh;
  background-color: #EDFFED;
  border-radius: 0.5vw;
}

.red{
  width: 2.75vw;
  height: 5.5vh;
  background-color: #FEE7E7;
  border-radius: 0.5vw;
}

.green-ball{
  width: 1.5vw;
  height: 3vh;
  background-color: #5FD377;
  border-radius: 1vw;
}

.red-ball{
  width: 1.5vw;
  height: 3vh;
  background-color: #F97878;
  border-radius: 1vw;
}


.container-green-red,
.green,
.red,
.green-ball,
.red-ball{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}


.iconName{
  width: 1.5vw;
  height: 1.5vw;
  background-color: #CAC0FA;
  border-radius: 0.75vw;
  display: flex;
  justify-content: center;
  align-items: center;
}